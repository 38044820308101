
<template>
    <div>
        <h3>Dashboard</h3>
        <b-card-group deck class="my-3">
            <b-card
                    class="card_value"
                    border-variant="primary"
                    header-bg-variant="primary"
                    header-text-variant="white"
                    align="center">
                <b-row>
                    <h4 class="card-title">Companies</h4>
                    <div id="chart">
                        <pie-chart id="chart1" ref="myChart" :data="chartData" :options="chartOptions"></pie-chart>
                    </div>
                </b-row>
            </b-card>
            <b-card
                    class="card_value"
                    border-variant="primary"

                    header-bg-variant="primary"
                    header-text-variant="white"
                    align="center">
                <b-row>
                    <h4 class="card-title">Projects</h4>
                    <div id="chart">
                        <pie-chart id="chart2" ref="myChart2" :data="chartData2" :options="chartOptions"></pie-chart>
                    </div>
                </b-row>
            </b-card>
            <b-card
                    class="card_value"
                    border-variant="primary"

                    header-bg-variant="primary"
                    header-text-variant="white"
                    align="center">
                <b-row>
                    <h4 class="card-title">Users</h4>
                    <div id="chart">
                        <pie-chart id="chart3" ref="myChart3" :data="chartData3" :options="chartOptions"></pie-chart>
                    </div>
                </b-row>
            </b-card>
            <b-card
                    class="card_value"
                    align="center"
                    border-variant="primary"

                    header-bg-variant="primary"
                    header-text-variant="white">
                <b-row>
                    <h4 class="card-title">Customers</h4>
                    <div id="chart">
                        <pie-chart id="chart4" ref="myChart4" :data="chartData4" :options="chartOptions"></pie-chart>
                    </div>
                </b-row>
            </b-card>

            <b-card align="center"
                    class="card_value card-testing"
                    border-variant="primary"

                    header-bg-variant="primary"
                    header-text-variant="white">
                <b-row>
                    <h4 class="card-title">Subscription Status</h4>
                    <div id="chart">
                        <pie-chart id="chart5" ref="myChart5" :data="chartData5" :options="chartOptions"></pie-chart>
                    </div>
                </b-row>
            </b-card>


        </b-card-group>


        <b-card-group deck class="ddddd">

            <b-card
                    class="card_value card-testing "
                    align="center"
                    border-variant="primary"

                    header-bg-variant="primary"
                    header-text-variant="white">
                <b-row>
                    <h4 class="card-title">Screenshots Per Day</h4>

                    <div class="barChart col-md-12">
                        <bar-chart :chart-data="barchartDatacollection"></bar-chart>
                    </div>

                </b-row>
            </b-card>

        </b-card-group>


    </div>
</template>

<script>
    import { mapState } from "vuex";
    import PieChart from "../chart.js";
    import BarChart from "../BarChart";
    import axios from "../assets/js/axios";

    export default {
        name: "Dashboard",
        components: {
            PieChart,
            BarChart
        },
        data () {
            return {
                testingvalue:[5,55,55],
                datarange:[
                ],
                height: 300,
                fields: {
                    capture_date: {
                        label: "Date",
                        thStyle: {
                            width: "45px"
                        }
                    },
                    count: {
                        label: "Count",
                        thStyle: {
                            width: "140px"
                        }
                    }
                },
                chartOptions: {
                    hoverBorderWidth: 200
                },
                chartData: {
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                    labels: ["Active", "Archived"],
                    datasets: [
                        {
                            // widgets.companies.active
                            label: "Data One",
                            backgroundColor: ["#41B883", "#E46651"],
                            data: [1,1]
                        } ],
                },
                chartData2: {
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                    labels: ["Active", "Archived"],
                    datasets: [
                        {
                            // widgets.companies.active
                            label: "Data One",
                            backgroundColor: ["#41B883", "#E46651"],
                            data: [1,1]
                        } ],
                },
                chartData3: {
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                    labels: ["Active", "Archived"],
                    datasets: [
                        {
                            // widgets.companies.active
                            label: "Data One",
                            backgroundColor: ["#41B883", "#E46651"],
                            data: [1,1]
                        } ],
                },
                chartData4: {
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                    labels: ["Subscribed", "On Trial"],
                    datasets: [
                        {
                            // widgets.companies.active
                            label: "Data One",
                            backgroundColor: ["#41B883", "#E46651"],
                            data: [1,1]
                        } ],
                },
                chartData5: {
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: 10,
                    labels: ["Over Due", "UnPaid","Cancelled"],
                    datasets: [
                        {
                            label: "Data One",
                            backgroundColor: ["#41B883", "#E46651","#6610f2"],
                            data: [1,1,1]
                            // data: [5,5,5]
                        } ],
                },
                barchartDatacollection: { labels: [], datasets: [], options: {} },
            }
        },

        computed: {
            ...mapState(["companies", "screenshots"]),
            widgets(){
                return this.$store.state.dashboard_widgets;
            },
            isBusy() {
                return this.$store.state.isBusy;
            },
        },

        created(){

            this.$store.commit("toggle_loader", true);
            // this.getDashboardChartData();
            // this.getScreenShotsChart();
            this.getDashboardCompany();

        },
        //
        methods:{

            getDashboardChartData(){

                this.$store.dispatch("get_dashboard_data_chart").then(response => {
                    this.addChartData(response.data.dashboard_widgets)
                    this.$store.commit("set_busy", false);
                    this.$store.commit("toggle_loader", false);
                }).catch(error => {
                    this.$store.commit("set_busy", false);
                    if (error.response) {
                        if (error.response.data.message === "Unauthenticated.") {
                            dispatch("reset").then(() => {
                                this.$store.commit("resetState");
                                location.reload(true);
                            });
                        }
                    }
                });

            },

            getScreenShotsChart(){

                this.$store.dispatch("getScreenShotsChart").then(response => {
                    this.barchartdata(response.data.screenshots);
                    this.$store.commit("set_busy", false);
                    this.$store.commit("toggle_loader", false);
                })
                    .catch(error => {
                        this.$store.commit("set_busy", false);
                        if (error.response) {
                            if (error.response.data.message === "Unauthenticated.") {
                                dispatch("reset").then(() => {
                                    this.$store.commit("resetState");
                                    location.reload(true);
                                });
                            }
                        }
                    });

            },

            addChartData(response){

                let companies =[response.companies.active,response.companies.archive]
                let project =[response.projects.active,response.projects.archive]
                let users =[response.projects.active,response.projects.archive]
                let customer =[response.customers.subscribed,response.customers.onTrial]
                let subscription =[response.customers.subscribed,response.subscription.unPaid,response.subscription.cancelled]
                this.chartData.datasets['0'].data = companies
                this.chartData2.datasets['0'].data = project
                this.chartData3.datasets['0'].data = users
                this.chartData4.datasets['0'].data = customer
                this.chartData5.datasets['0'].data = subscription

            },

            getFields(input, field) {
                var output = [];
                for (var i=0; i < input.length ; ++i)
                    output.push(input[i][field]);
                return output;
            },

            barchartdata(response) {
                this.barchartDatacollection = {
                    labels: this.getFields(response, "capture_date"),
                    datasets: [
                        {
                            label: "Screenshots",
                            data: this.getFields(response, "count"),
                            backgroundColor: "#512DA8",
                            hoverBackgroundColor: "#7E57C2",
                            hoverBorderWidth: 0
                        }
                    ],
                    options: {
                        scales: {
                            xAxes: [
                                {
                                    stacked: true,
                                    // gridLines: { display: true }
                                }
                            ],
                            yAxes: [
                                {
                                    stacked: true
                                }
                            ]
                        },
                        legend: { display: true }
                    }
                };
            },
        },
    };
</script>

<style scoped>

    #chart {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }


    .chartjs-render-monitor {
        animation: chartjs-render-animation 0.001s;
        height: 250px !important;
        margin-left: 26% !important;
        margin-top: -19% !important;
    }
    .card-deck ddddd{
        margin-left: 122px;
        margin-right: 176px;

    }
    #chart4{
        /*margin-left: 113px;*/
    }
    #chart{

        width: 100%;
        /*height: 30%;*/

    }
    /*.chartjs-render-monitor{*/
    /*    margin-top: -47px !important;*/
    /*    margin-left: 181px !important;*/
    /*}*/
    .card tttt{
        max-width: 12rem;
        height: 141px !important;
    }

    .responsive-charts{
        width: 70vw;
        height: 30vh;
        position: relative;
    }



</style>

<style >

    .small {
        width: 700px!important;
        /*height: 300px!important;*/
        margin:  0px auto;
    }

    .card-header{
        display: -webkit-box;
        display: flex;
        -ms-flex: 1 0 0%;
        -webkit-box-flex: 1;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        /*margin-right: 15px;*/
        margin-bottom: 0;
        margin-left: 15px;
    }
    .card-body{
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1.25rem;
    }

    .card_value{

        border-radius: 10px;
        -webkit-box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
        box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
        border: 0;
    }

    .card-title{
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
        text-align: center;
        display: block;
        color: #332e38;
        width: 100%;
        margin-top: 20px;
    }
    .change_value{
        max-width: 30% !important;
        height: 500px!important;
    }

    .bar-cahrt-value{
        max-width: 70% !important;
        height: 500px!important;
    }

    canvas#bar-chart {
        height: 800px !important;
    }

</style>
